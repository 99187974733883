import axios from 'axios'

import { baseUrl } from '@/references/config'
import store from '@/store'

const reAuthRequest = async () => {
  let requestMethod = "post";
  let requestTaget = "api/user/userReLogin";
  let requestHeaders = {};
  requestHeaders.accept = "application/json";
  requestHeaders.accessToken = store.getters.getCurrentUser.at
  requestHeaders.refreshToken = store.getters.getCurrentUser.rt
  requestHeaders.checkSum = store.getters.getCurrentUser.cs

  let retVal = {};
  const response = await axios({
      url: requestTaget,
      method: requestMethod,
      baseURL: baseUrl,
      params: {},
      data: { userID: store.getters.getCurrentUser.ur.userID },
      headers: requestHeaders
  });

  console.log("response", response)

  retVal = response.data;
  let retObj = {}
  if(retVal.retString=="success") {
      retObj.message = "success"
      retObj.accessToken = retVal.retMessage.accessToken
  }
  else {
      retObj.message = "error"
      retObj.accessToken = ""
  }

  return retObj
}

axios.interceptors.request.use(async function (config) {
  if(store.getters.getCurrentUser != null) {
      config.headers.common['accessToken'] = `${store.getters.getCurrentUser.at}`;
      config.headers.common['refreshToken'] = `${store.getters.getCurrentUser.rt}`;
      config.headers.common['checkSum'] = `${store.getters.getCurrentUser.cs}`;
      // config.headers.common['Authorization'] = `Bearer ${store.getters.getCurrentUser.at}`;
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
  return response;
}, async function (error) {
  console.log("respose", error)
  if(error.response.status=="419") {
      console.log("access token 처리")
      let reAuthResult = await reAuthRequest();
      if(reAuthResult.message=="success") {
          const item = store.getters.getCurrentUser;
          item.at = reAuthResult.accessToken;
          store.dispatch('chgCurrentUser', item)

          // error.config.headers.Authorization = `Bearer ${reAuthResult.accessToken}`;
          error.config.headers.accessToken = reAuthResult.accessToken;

          return axios(error.config);
      }
  }
  // else {
  //     setCurrentUser(null);
  //     router.push({name:"loginPage"})
  // }
  return Promise.reject(error);
});

export const requestApi = async (req) => {
  const retVal = {}
  const reqObj = {}
  reqObj.baseURL = baseUrl
  reqObj.url = req.target
  reqObj.method = req.method
  if (req.params) reqObj.params = req.params
  if (req.data) reqObj.data = req.data
  if (req.headers) reqObj.headers = req.headers
  if (req.responseType) reqObj.responseType = req.responseType

  try {
    const resp = await axios(reqObj)

    retVal.result = 'sucess'
    retVal.status = true
    retVal.code = '2000'
    retVal.msg = resp.data
  } catch (e) {
    if (e.response) {
      retVal.result = 'error'
      retVal.status = false
      retVal.code = '9990-' + e.response.status
      retVal.msg = e.response.data
    } else if (e.request) {
      retVal.result = 'error'
      retVal.status = false
      retVal.code = '9991'
      retVal.msg = 'request timeout'
    } else {
      retVal.result = 'error'
      retVal.status = false
      retVal.code = '9992'
      retVal.msg = e
    }
  }
  // console.log(req.target, retVal)
  return retVal
}

export const requestGetDownload = async (url, fileName) => {
  axios({
    baseURL: baseUrl,
    url: url,
    method: 'GET',
    responseType: 'blob',
  }).then((response) => {
     var fileURL = window.URL.createObjectURL(new Blob([response.data]));
     var fileLink = document.createElement('a');

     fileLink.href = fileURL;
     fileLink.setAttribute('download', fileName);
     document.body.appendChild(fileLink);

     fileLink.click();
  });
}

export const requestPostDownload = async (url, data, fileName) => {
  axios({
    baseURL: baseUrl,
    url: url,
    method: 'POST',
    data: data,
    responseType: 'blob',
  }).then((response) => {
     var fileURL = window.URL.createObjectURL(new Blob([response.data]));
     var fileLink = document.createElement('a');

     fileLink.href = fileURL;
     fileLink.setAttribute('download', fileName);
     document.body.appendChild(fileLink);

     fileLink.click();
  });
}