<template lang="html">
  <div class="content-box">
    <div class="title-area">
      <h2 class="title-b">대시보드</h2>
    </div>
    <div class="search-area mt-s">
      <div class="search-item">
        <div class="datepicker-in-box flex items-center py-1">
          <i class="icon-img ic-calendar"></i>
          <t-datepicker
            class="element-input-date"
            v-model="searchDate"
            range
            :clearable="false"
            date-format="Y-m-d"
            user-format="Y-m-d"
            rangeSeparator=" ~ "
            @hidden="changeDateRange"
          >
          </t-datepicker>
        </div>

        <t-rich-select
          v-if="getUserRole === 'master'"
          :options="companySearchList"
          class="border border-solid rounded-md py-1 mx-2 text-gray-700 border-gray-300 outline-none w-52"
          textAttribute="companyName"
          valueAttribute="companyGuid"
          :hideSearchBox="true"
          v-model="companySearchGuid"
          @change="selectCompany"
          placeholder="고객사/운용부서"
          noResultsText="등록된 고객사가 없습니다"
          :clearable="true"
        >
        </t-rich-select>

        <t-rich-select
          :options="siteSearchList"
          class="border border-solid rounded-md py-1 mx-2 text-gray-700 border-gray-300 outline-none w-52"
          textAttribute="siteName"
          valueAttribute="siteGuid"
          :hideSearchBox="true"
          v-model="siteSearchGuid"
          @change="selectSite"
          placeholder="관리구역"
          noResultsText="등록된 관리구역이 없습니다."
          :clearable="true"
        >
        </t-rich-select>

        <t-rich-select
          :options="mapSearchList"
          class="border border-solid rounded-md py-1 mx-2 text-gray-700 border-gray-300 outline-none w-52"
          textAttribute="mapName"
          valueAttribute="mapGuid"
          :hideSearchBox="true"
          v-model="mapSearchGuid"
          placeholder="상세위치"
          noResultsText="등록된 상세위치가 없습니다"
          :clearable="true"
        >
        </t-rich-select>

        <button type="button" class="element-btn bg-blue w-150" @click="initDevice()">검색</button>
      </div>
      <div class="btn-item">
      </div>
    </div>

    <div ref="pdfArea" :key="pdfKey">
      <div class="line-group reverse-type mt-25">
        <div>
          <h3 class="text-left text-lg font-bold text-gray-600">현재 단말기 현황</h3>
          <div class="group-box block my-3">
            <div class="box-item">
              <p class="title">현장 수</p>
              <p class="value blue">{{mapList.length}}곳</p>
            </div>
            <div class="box-item">
              <p class="title">단말기 수</p>
              <p class="value purple">{{deviceList.length}}대</p>
            </div>
            <!-- <div class="box-item">
              <p class="title">탐지 수</p>
              <p class="value navy">{{mainDetectCount}}대</p>
            </div>
            <div class="box-item">
              <p class="title">차단 수</p>
              <p class="value red">{{mainBlockCount}}대</p>
            </div> -->
          </div>

          
          <h3 class="text-left text-lg font-bold text-gray-600 mt-10">날짜 별 탐지 현황 <span class="text-sm text-gray-600">({{searchDate[0]}} ~ {{searchDate[1]}})</span></h3>
          <div class="group-box block my-3">
            <div class="box-item">
              <p class="title">탐지 수</p>
              <p class="value navy">{{sumDetectCount}}대</p>
            </div>
            <div class="box-item">
              <p class="title">차단 수</p>
              <p class="value red">{{sumBlockCount}}대</p>
            </div>
            <div class="box-item">
              <p class="title">해제 수</p>
              <p class="value purple">{{sumClearCount}}대</p>
            </div>
          </div>
        </div>

        

        

        <div class="ml-20">
          <v-chart class="w-full" style="height:500px;width:500px;" :option="countDevice" autoresize />
        </div>

        <div class="side-btn">
          <button type="button" class="btn-b" @click="reportDownloadAction()">
            <i class="icon-img ic-dash-excel-down"></i>
            보고서 다운로드
          </button>
        </div>
      </div>

      

      <div class="title-area">
        <h2 class="title-a">탐지현황</h2>
      </div>
      
      <div class="line-chart-group mt-25">
        <DashboardLine legendName="탐지현황" :dateRange="dateRange" :chartData="detectData" chartColor="#fc8452"></DashboardLine>
      </div>

      <div class="title-area">
        <h2 class="title-a">차단현황</h2>
      </div>
      
      <div class="line-chart-group mt-25">
        <DashboardLine legendName="차단현황" :dateRange="dateRange" :chartData="blockData" chartColor="#3ba272"></DashboardLine>
      </div>

      <div class="title-area">
        <h2 class="title-a">해제현황</h2>
      </div>
      
      <div class="line-chart-group mt-25">
        <DashboardLine legendName="해제현황" :dateRange="dateRange" :chartData="clearData" chartColor="#5470c6"></DashboardLine>
      </div>
    </div>
    
  </div>
</template>

<script>
// import { requestApi, requestGetDownload, requestPostDownload } from "@/utils/api"
import { requestApi, requestGetDownload } from "@/utils/api"
import { mapGetters, mapActions } from "vuex"

import { getDateRangeArray } from "@/utils/config"

import DashboardLine from "@/components/elements/DashboardLine"

import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart, GaugeChart, BarChart, PieChart } from "echarts/charts";
import { TitleComponent, TooltipComponent, LegendComponent,ToolboxComponent, GridComponent } from "echarts/components";
import VChart from "vue-echarts";

use([CanvasRenderer,LineChart,GaugeChart,BarChart,PieChart,TitleComponent,TooltipComponent,ToolboxComponent,GridComponent,LegendComponent]);

export default {
  async created () {
    this.searchDate.push(this.$moment().startOf('month').format('YYYY-MM-DD'))
    this.searchDate.push(this.$moment().format('YYYY-MM-DD'))

    this.getCompanyListAll();
    if(this.getCompanyGuid != "0") {
      this.companySearchGuid = this.getCompanyGuid
      this.getSiteListAll()
    }

    await this.initDevice();
    // this.lineOptions.xAxis.data = getDateRangeArray(this.searchDate[0], this.searchDate[1])
    this.dateRange = getDateRangeArray(this.searchDate[0], this.searchDate[1])
  },

  components: {
    "v-chart": VChart,
    DashboardLine
  },

  computed: {
    ...mapGetters(["getIsOverlay","getUserRole","getCompanyGuid"]),
  },
  data () {
    return {
      searchDate: [],
      companySearchGuid:'',
      siteSearchGuid:'',
      mapSearchGuid:'',

      companySearchList: [],
      siteSearchList: [],
      mapSearchList: [],

      mapList: [],
      deviceList: [],

      mainOnLineCount: 0,
      mainOffLineCount: 0,
      mainDetectCount: 0,
      mainBlockCount: 0,
      mainEventCount: 0,

      sumDetectCount: 0,
      sumBlockCount: 0,
      sumClearCount: 0,

      dateArray: [],

      chartData: {},

      countDevice: {
        title: {
          text: '단말기 상태별 현황',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            type: 'pie',
            radius: '50%',
            clockwise: true,
            selectedMode: true,
            data: [],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      },
      detectData: [],
      blockData: [],
      clearData: [],
      dateRange: [],

      pdfKey: 0,
    }
  },
  methods: {
    ...mapActions(["chgIsOverlay"]),
    changeDateRange () {
    },
    selectCompany () {
      this.siteSearchGuid = ''
      this.getSiteListAll()
    },
    selectSite () {
      this.mapSearchGuid = ''
      this.getMapListAll()
    },
    selectMap () {
    },

    async initDevice () {
      await this.getHomeMapList()
      await this.getHomeDeviceList()

      this.mainDetectCount = 0;
      this.mainBlockCount = 0;
      this.mainEventCount = 0;

      if(this.companySearchGuid) {
        this.mapList = this.mapList.filter(x => x.companyGuid === this.companySearchGuid)
        this.deviceList = this.deviceList.filter(x => x.companyGuid === this.companySearchGuid)
      }
      if(this.siteSearchGuid) {
        this.mapList = this.mapList.filter(x => x.siteGuid === this.siteSearchGuid)
        this.deviceList = this.deviceList.filter(x => x.siteGuid === this.siteSearchGuid)
      }
      if(this.mapSearchGuid) {
        this.mapList = this.mapList.filter(x => x.mapGuid === this.mapSearchGuid)
        this.deviceList = this.deviceList.filter(x => x.mapGuid === this.mapSearchGuid)

      }

      for(let i=0; i<this.mapList.length; i++) {
        let deviceList = this.deviceList.filter(x => x.mapGuid === this.mapList[i].mapGuid)
        
        let detectCount = deviceList.map(item => item.detectCount).reduce((prev, curr) => prev + curr, 0);
        let blockCount = deviceList.map(item => item.blockCount).reduce((prev, curr) => prev + curr, 0);
        let eventCount = deviceList.map(item => item.eventCount).reduce((prev, curr) => prev + curr, 0);
        
        this.mainDetectCount += detectCount
        this.mainBlockCount += blockCount
        this.mainEventCount += eventCount

        this.mapList[i].deviceList = deviceList
        
        this.mapList[i].detectCount = detectCount
        this.mapList[i].blockCount = blockCount
        this.mapList[i].eventCount = eventCount
      }

      const allDeviceCount = this.mapList.map(item => item.deviceCount).reduce((prev, curr) => prev + curr, 0);
      this.mainOffLineCount = this.mapList.map(item => item.offlineCount).reduce((prev, curr) => prev + curr, 0);
      this.mainOnLineCount = allDeviceCount - this.mainOffLineCount

      this.countDevice.series[0].data = [{name:'온라인', value:this.mainOnLineCount},{name:'오프라인', value:this.mainOffLineCount}]

      // this.lineOptions.xAxis.data = getDateRangeArray(this.searchDate[0], this.searchDate[1])
      this.dateRange = getDateRangeArray(this.searchDate[0], this.searchDate[1])
      this.getDashboardCountList()
    },

    async getHomeMapList () {
      let guid = this.companySearchGuid;
      if(this.companySearchGuid === '' || this.companySearchGuid == null) guid = this.getCompanyGuid

      let reqObj = {}
      reqObj.target = `/home/maplist/${guid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        if(result.msg.retData.length !== 0) {
          this.mapList = result.msg.retData;
        }
      }
    },

    async getHomeDeviceList () {
      let guid = this.companySearchGuid;
      if(this.companySearchGuid === '' || this.companySearchGuid == null) guid = this.getCompanyGuid

      let reqObj = {}
      reqObj.target = `/home/devicelistall/${guid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.deviceList = result.msg.retData;
      }
    },

    async getDashboardCountList () {
      let startDate = this.$moment(this.searchDate[0] + " 00:00:00").valueOf();
      let endDate = this.$moment(this.searchDate[1] + " 23:59:59").valueOf();

      let guid = this.companySearchGuid;
      if(this.companySearchGuid === '' || this.companySearchGuid == null) guid = this.getCompanyGuid


      let reqObj = {}
      reqObj.target = `/dashboard/count/${guid}/${this.siteSearchGuid ? this.siteSearchGuid : 0}/${this.mapSearchGuid ? this.mapSearchGuid : 0}`;
      reqObj.method = "get";
      reqObj.params = {
        "startTimestamp": startDate,
        "endTimestamp": endDate
      };
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        // this.detectOptions.series[0].data = this.makeStatData(result.msg.retData.detect)
        // this.blockOptions.series[0].data = this.makeStatData(result.msg.retData.block)
        // this.clearOptions.series[0].data = this.makeStatData(result.msg.retData.clear)
        
        let detectMerge = [...result.msg.retData.detect, ...result.msg.retData.history]

        let detectSum = Array.from(detectMerge.reduce(
          (m, {statDate, statCount}) => m.set(statDate, (m.get(statDate) || 0) + statCount), new Map
        ), ([statDate, statCount]) => ({statDate, statCount}));

        this.detectData = this.makeStatData(detectSum)
        this.blockData = this.makeStatData(result.msg.retData.block)
        this.clearData = this.makeStatData(result.msg.retData.clear)

        this.sumDetectCount = this.detectData.reduce((a, b) => a + b, 0);
        this.sumBlockCount = this.blockData.reduce((a, b) => a + b, 0);
        this.sumClearCount = this.clearData.reduce((a, b) => a + b, 0);

        this.chartData = result.msg.retData
      }
    },


    makeStatData (statData) {
      let dateArray = getDateRangeArray(this.searchDate[0], this.searchDate[1])
      
      let finalData = [];
      for(let x = 0; x < dateArray.length; x++) {
        let findData = statData.find(z => z.statDate == dateArray[x])
        if(typeof findData === "undefined") finalData.push(0)
        else finalData.push(findData.statCount)
      }
      
      return finalData
    },

    async getCompanyListAll () {
      let reqObj = {}
      reqObj.target = "/company/listall";
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      this.companySearchList = []
      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.companySearchList = result.msg.retData
      }
    },

    async getSiteListAll () {
      let reqObj = {}
      reqObj.target = `/site/list/${this.companySearchGuid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      this.siteSearchList = []
      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.siteSearchList = result.msg.retData
      }
    },

    async getMapListAll () {
      let reqObj = {}
      reqObj.target = `/map/list/${this.siteSearchGuid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      this.mapSearchList = []
      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.mapSearchList = result.msg.retData
      }
    },

    reportDownloadAction () {
      this.reportDownloadActionSubmit()
    },
    async reportDownloadActionSubmit () {
      // const name = 'dashboard_report';
      // this.$htmlToPdf(this.$refs.pdfArea, name);
      // this.pdfKey += 1;

      let startDate = this.$moment(this.searchDate[0] + " 00:00:00").valueOf();
      let endDate = this.$moment(this.searchDate[1] + " 23:59:59").valueOf();

      let guid = this.companySearchGuid;
      if(this.companySearchGuid === '' || this.companySearchGuid == null) guid = this.getCompanyGuid

      let url = `/dashboard/statDownload/${guid}/${this.siteSearchGuid ? this.siteSearchGuid : 0}/${this.mapSearchGuid ? this.mapSearchGuid : 0}/?startTimestamp=${startDate}&endTimestamp=${endDate}`;

      let result = await requestGetDownload(url, "대시보드.xlsx");
      console.log(result)
    }
  }
  
}
</script>