const apiUrl = window.location.hostname
const apiPort = process.env.VUE_APP_API_PORT ? process.env.VUE_APP_API_PORT : "6443";
const apiMethod = 'https'
const apiSocket = 'wss'

export const baseAddr = apiUrl;
export const basePort = apiPort;
export const baseSocket = apiSocket

export const baseUrl = `${apiMethod}://${apiUrl}:${apiPort}/api`
export const defaultLocale = 'ko'
export const logoTitle = '이너트론'

export const divisionChar = 'ⓘⓝⓝⓔⓡⓣⓡⓞⓝ'   //ⓐⓑⓒⓓⓔⓕⓖⓗⓘⓙⓚⓛⓜⓝⓞⓟⓠⓡⓢⓣⓤⓥⓦⓧⓨⓩ

export const speedCircle = {
  id: 'smcom',
  width: 200,
  height: 200,
  displayValue: 'SMCOM',
  displayColor: "#343a42",
  isAlert: false,
  options: {
    speed1: 0,
    color1: "#007bfb",
    speed2: 0,
    color2: "#28a748",
    speed3: 0,
    color3: "#17a2b9",
    speed4: 0,
    color4: "#6c757e",
  },
  alarms: {
    speed1: 1,
    color1: "#007bfb",
    speed2: -2,
    color2: "#28a748",
    speed3: 1,
    color3: "#17a2b9",
    speed4: -1,
    color4: "#6c757e",
  }
}

export const detectStatus = {
  "block": "차단",
  "detect": "탐지",
  "clear": "해제"
}

// export const deviceStatusList = {
//   "unknown": "미확인",
//   "regist": "등록",
//   "unregist": "미등록"
// }

export const deviceStatusList = {
  "unknown": "미설치",
  "regist": "온라인",
  "unregist": "오프라인"
}

export const managerRole = {
  "master": "시스템관리자",
  "admin": "관리자",
  "member": "운영자",
  "agent": "현장관리자"
}

export const soundTypeDesc = {
  "detect": "불법 카메라 탐지",
  "offline": "단말기 오프라인"
}

export const blockActionList = {
  "none": "조치내역 없음",
  "normal": "이상무",
  "visit": "방문예정",
  "collection": "수거"
}

export const cameraType = {
  "0": "무선",
  "1": "유선"
}

export const eventTypeDesc = {
  "detect": "불법 카메라 탐지",
  "offline": "단말기 오프라인"
}

export const eventList = {
  "SET_ALIVE_REQ": "Set Alive",
  "SET_INFORMATION_REQ": "Set Information",
  "SET_DETECT_LIST_NEW_REQ": "Set Detect List Start",
  "SET_DETECT_LIST_REQ": "Set Detect List",
  "SET_DETECT_LIST_END_REQ": "Set Detect List End",
  "GET_BLOCK_LIST_REQ": "Get Block List",
  "TRANSFER_BLOCK_LIST_REQ": "Transfer Block List",
  "GET_WIFI_DB_REQ": "Get Wifi DB",
  "TRANSFER_WIFI_DB_REQ": "Transfer Wifi DB",
  "GET_UPGRADE_INFO_REQ": "Get Firmware",
  "TRANSFER_FIRMWARE_BIN_REQ": "Transfer Firmware"
}

export const eventSearchList = [
  {id: 'SET_ALIVE_REQ', value: 'Set Alive'},
  {id: 'SET_INFORMATION_REQ', value: 'Set Information'},
  {id: 'SET_DETECT_LIST_NEW_REQ', value: 'Set Detect List Start'},
  {id: 'SET_DETECT_LIST_REQ', value: 'Set Detect List'},
  {id: 'SET_DETECT_LIST_END_REQ', value: 'Set Detect List End'},
  {id: 'GET_BLOCK_LIST_REQ', value: 'Get Block List'},
  {id: 'TRANSFER_BLOCK_LIST_REQ', value: 'Transfer Block List'},
  {id: 'GET_WIFI_DB_REQ', value: 'Get Wifi DB'},
  {id: 'TRANSFER_WIFI_DB_REQ', value: 'Transfer Wifi DB'},
  {id: 'GET_UPGRADE_INFO_REQ', value: 'Get Firmware'},
  {id: 'TRANSFER_FIRMWARE_BIN_REQ', value: 'Transfer Firmware'},
]

// export const detectMinuteList = [
//   {id:0,name:'즉시전송'},
//   {id:5,name:'5분 후'},
//   {id:10,name:'10분 후'},
//   {id:20,name:'20분 후'},
//   {id:30,name:'30분 후'},
// ]

// 20240429 요청으로 탐지 전송 시간 수정
export const detectMinuteList = [
  {id:0,name:'즉시전송'},
  {id:30,name:'30분 후'},
  {id:60,name:'1시간 후'},
  {id:720,name:'12시간 후'},
  {id:1440,name:'24시간 후'},
]

export const deviceLogDayList = [
  {id:0,name:'당일만보관'},
  {id:7,name:'1주일'},
  {id:10,name:'10일'},
  {id:15,name:'15일'},
  {id:30,name:'30일'},
]